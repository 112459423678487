<template>
  <section style="transition: all .5s ease;">
    <div class="modal d-flex justify-content-end text-[#333]" v-if="!loading">
      <div class="wrapper-rightdrawer bg-white p-2 w-[600px] flex flex-col gap-[24px]">
        <div class="flex items-center justify-between font-semibold text-[20px]">
          <span class="">Target</span>
          <span
            class="k-close-circle cursor-pointer"
            @click="$emit('close')"
          />
        </div>
        <div class="flex flex-col gap-[20px]">
          <div class="flex gap-[10px]">
            <span class="w-[144px]">Nama Talent</span>: <span>{{ dataDetail.full_name }}</span>
          </div>
          <div class="flex gap-[10px]">
            <span class="w-[144px]">Team Lead</span>: <span>{{ dataDetail.full_name }}</span>
          </div>
          <div class="flex gap-[10px]">
            <span class="w-[144px]">Partner</span>: <span>{{ dataDetail.partner_id }} - {{ dataDetail.partner_name }}</span>
          </div>
        </div>
        <Product
          v-for="product in mappedProducts"
          :key="product.product_id"
          :product="productUpdate ? productUpdate : product"
          :user-id="dataDetail.user_id"
          @productUpdate="(e) => productUpdate = e"
        />
      </div>
    </div>
    <b-overlay
      v-if="loading"
      show
    />
    <div class="modal-backdrop" />
  </section>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import Product from './Product.vue'

export default {
  components: {
    Product,
  },
  props: {
    idActive: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dataDetail: {},
      alertError,
      productUpdate: null,
      loading: false,
    }
  },
  computed: {
    mappedProducts() {
      if (this.dataDetail.products) {
        const mappedData = this.dataDetail.products.map(e => ({ ...e, initLeads: e.leads, initCr: e.closing_rate }))
        return mappedData
      }

      return {}
    },
  },
  mounted() {
    this.getDataDetail()
  },
  methods: {
    async getDataDetail() {
      this.loading = true
      const url = `v1/talent_performance/detail?id=${this.idActive}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.dataDetail = data
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
  },
}
</script>

<style>
div.date-popover.visible {
  top: 30px;
  left: 25px !important;
}
div.week {
  width: 40px;
  height: 40px;
  background: #FCEBBE;
  font-weight: 600;
  color: #FBA63C;
}
.wrapper-rightdrawer {
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}
.wrapper-rightdrawer::-webkit-scrollbar {
  display: none;
}
</style>
